import { get, partial } from 'lodash';

import { flushGqlTiming, getEventLogger, setTransitionInfo } from './event-logger';
import { getHashPath } from './utils/urlUtils';
import { subscribeToSend, unsubscribeToSend, Subscriber } from './fetchWrapper';
import { sendHardNavigation, sendSoftNavigation } from './performanceMetrics';

declare global {
  interface Window {
    BOOMR: any;
    BOOMR_initialized: boolean;
    BOOMR_onload: number;
  }
}

type Timing = { requestStart: number; loadEventEnd?: number; fetchStart?: number; responseStart?: number };

type XHRResource = {
  restiming?: PerformanceResourceTiming;
  existingResource?: boolean;
  url: string;
  requestFailed?: boolean;
  timing?: Timing;
  endTime?: number;
};

type BoomResource = {
  src?: string;
  href?: string;
};

type Beacon = {
  [key: string]: string | XHRResource[] | BoomResource[];
  xhrResources: XHRResource[];
  resources: BoomResource[];
  u: string;
};

export type BoomerangResource = {
  initiator: string;
  isComplete: boolean;
  onFinish: (callback: Function) => void;
  timing: Timing;
  requestFailed?: boolean;
  endTime?: number;
};

let firstReactRenderTimestamp: number;

function load() {
  const existingScript = document.getElementById('boomerang');
  if (existingScript) {
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `${__PUBLIC_PATH__}boomerang-v1.650.0-zenefits.12.1.js`;
  script.id = 'boomerang';
  const x = document.getElementsByTagName('script')[0];
  // @ts-ignore
  x.parentNode.insertBefore(script, x);
}

const xhrExcludes = {
  '/session_ping': true, // Don't wait for session ping when deciding if navigation is done
  '/session_test': true, // Don't wait for session ping when deciding if navigation is done
};

export function bootBoomerang(options: BoomerangOptions, switches: Record<string, string>) {
  try {
    initOnLoad(options, switches);
    load();
  } catch (e) {
    getEventLogger().logError(e);
  }
}

export function setFirstReactRender(timestamp: number) {
  firstReactRenderTimestamp = timestamp;
}

let fetchSubscription: Subscriber;
export function setupFetchSubscription(options: BoomerangOptions) {
  fetchSubscription = addExistingResource(options);
  subscribeToSend(fetchSubscription);
}

export function cleanupFetchSubscription() {
  unsubscribeToSend(fetchSubscription);
}

const resourcesForInit: BoomerangResource[] = [];

const reactInitializationOptions = {
  History: {
    enabled: true,
    auto: true,
    routeChangeProgressTimeout: 400,
  },
  AutoXHR: {
    monitorFetch: true,
    ignoreImages: false,
    ignoreAddedStylesheets: false,
  },
  instrument_xhr: true,
  autorun: false,
  useDOMContentLoaded: true,
};

const emberInitializationOptions = {
  ...reactInitializationOptions,
  AutoXHR: {
    ...reactInitializationOptions.AutoXHR,
    alwaysSendXhr: false,
  },
  History: {
    ...reactInitializationOptions.History,
    routeChangeWaitFilter: () => true, // In ember we manually call BOOMR.plugins.span.wait_complete on didTransition
  },
};

const addExistingResource: (options: BoomerangOptions) => Subscriber = (options: BoomerangOptions) => ({
  promise,
  input,
  init,
}) => {
  // Skip any background queries
  const headers = init && init.headers && (init.headers as { [key: string]: string });
  if (headers && (headers['IS-BACKGROUND-QUERY'] || headers['is-background-query'])) {
    return;
  }

  const url = typeof input === 'string' ? input : input.url;
  if (options.xhrExcludeFilters) {
    const filtered = options.xhrExcludeFilters.some(filter => filter(url));
    if (filtered) {
      return;
    }
  }
  if (Object.keys(xhrExcludes).includes(url)) {
    return;
  }

  const absoluteUrl = new URL(url, document.baseURI).href;
  if (!absoluteUrl.includes(window.location.hostname)) {
    return;
  }

  // Find associated timing info
  // TODO get timing data from performance timing api
  const timing = { requestStart: Date.now() } as Timing;

  const callbacks: (() => void)[] = [];
  const onFinish = (cb: any) => {
    callbacks.push(cb);
  };

  const resource = {
    timing,
    onFinish,
    url: absoluteUrl,
    initiator: 'xhr',
    isComplete: false,
    existingResource: true,
    requestFailed: false,
  } as BoomerangResource;

  function completeResource(resBodyUsed: boolean, requestFailed: boolean) {
    resource.requestFailed = requestFailed;

    // Boomerang will not wait for this resource if isComplete is set
    resource.isComplete = true;
    // eslint-disable-next-line compat/compat
    resource.endTime = performance.now();

    const doneTime = Date.now();
    // Boomerang relies on the browser ResourceTiming. If the body hasn't been used we need to give it time to be created.
    // Copied from From https://github.com/zenefits/boomerang/blob/master/plugins/auto-xhr.js#L1728
    if (resBodyUsed) {
      callbacks.forEach(cb => cb());
      resource.timing.loadEventEnd = resource.timing.loadEventEnd || doneTime;
    } else {
      setTimeout(() => {
        callbacks.forEach(cb => cb());
        resource.timing.loadEventEnd = resource.timing.loadEventEnd || doneTime;
      }, 200);
    }
  }

  promise
    .then(res => {
      completeResource(res.bodyUsed, false);
    })
    .catch(err => {
      completeResource(false, true);

      throw err;
    });

  resourcesForInit.push(resource);
};

function setupSubscriptions(BOOMR: Window['BOOMR'], options: BoomerangOptions, switches: Record<string, string>) {
  window.BOOMR.subscribe('before_beacon', partial(sendPageView, options || {}, switches));

  // Transition starting
  window.BOOMR.subscribe('spa_init', () => {
    const currRoute = getHashPath(window.location.href);
    setTransitionInfo(currRoute);
    BOOMR.addVar('transitionId', getEventLogger().transitionId);
  });

  // Transition ended
  window.BOOMR.subscribe('spa_navigation', () => {
    getEventLogger().transitionId = null;
  });
}

export function getMinPageLoad(resources: BoomerangResource[], firstRenderTime: number) {
  const completedResources = resources.filter(resource => resource.isComplete);

  // There's still work to be done
  if (!completedResources.length) {
    return firstRenderTime;
  }

  return Math.max(...completedResources.map(resource => resource.timing.loadEventEnd || 0));
}

let initializedMinPageLoad: number;

function init(options: BoomerangOptions, switches: Record<string, string>) {
  const minPageLoadTime = getMinPageLoad(resourcesForInit, firstReactRenderTimestamp);
  initializedMinPageLoad = minPageLoadTime;

  // Tells boomerang to treat this time as pageReady
  window.BOOMR_onload = minPageLoadTime;
  try {
    if (window.BOOMR && !window.BOOMR_initialized) {
      window.BOOMR_initialized = true;
      if (!window.__WITHIN_EMBER_APP__ || (options && options.enableBoomerangInEmber)) {
        setupSubscriptions(window.BOOMR, options, switches);
      } else {
        // We Q gql timing until we enter our 1st transition but we aren't using boomerang tracking in this case
        flushGqlTiming();
      }

      const initializionOptions =
        // TODO  Using enableBoomerangInEmber is a hack to initialze with react options for react-dashboard
        window.__WITHIN_EMBER_APP__ && !(options && options.enableBoomerangInEmber)
          ? emberInitializationOptions
          : {
              ...reactInitializationOptions,
              AutoXHR: {
                ...reactInitializationOptions.AutoXHR,
                existingResources: resourcesForInit.filter(resource => !resource.isComplete),
              },
              Spa: {
                min_end_time: minPageLoadTime,
              },
            };
      if (typeof options.ignoreImages === 'boolean') {
        initializionOptions.AutoXHR.ignoreImages = options.ignoreImages;
      }

      if (typeof options.ignoreAddedStylesheets === 'boolean') {
        initializionOptions.AutoXHR.ignoreAddedStylesheets = options.ignoreAddedStylesheets;
      }

      window.BOOMR.init(initializionOptions);

      window.BOOMR.xhr_excludes = xhrExcludes;

      if (options.xhrExcludeFilters) {
        options.xhrExcludeFilters.forEach(filter =>
          window.BOOMR.plugins.AutoXHR.addExcludeFilter((linkEl: HTMLLinkElement) => filter(linkEl.href)),
        );
      }

      window.BOOMR.plugins.AutoXHR.addExcludeFilter(
        (linkEl: HTMLLinkElement, options?: { [key: string]: any }) => {
          if (
            get(options, 'fetchOptions.init.headers.IS-BACKGROUND-QUERY') ||
            get(options, 'fetchOptions.init.headers.is-background-query')
          ) {
            return true;
          }

          if (linkEl.href && !linkEl.href.includes(window.location.hostname)) {
            return true;
          }
          return false;
        },
        null,
        'backgroundFetch',
      );

      window.BOOMR.page_ready();
    }
  } catch (e) {
    getEventLogger().logError(e);
  } finally {
    cleanupFetchSubscription();
  }
}

export type BoomerangOptions = {
  ignoreImages?: boolean;
  ignoreAddedStylesheets?: boolean;
  enableBoomerangInEmber?: boolean;
  // Called after a transition completes. Return false to avoid sending a pageView event
  trackTransition?: (newRoute: string, switches: Record<string, string>) => boolean;
  xhrExcludeFilters?: ((url: string) => boolean)[];
  pageReadyTime?: number;
};

export function initOnLoad(options: BoomerangOptions, switches: Record<string, string>) {
  // Usually boomerang won't be loaded yet but leaving this here just in case
  if (window.BOOMR) {
    init(options, switches);
  } else {
    document.addEventListener('onBoomerangLoaded', () => init(options, switches));
  }
}

// NOTE - Doesn't work with memory router currently
function sendPageView(options: BoomerangOptions, switches: Record<string, string>, beacon: Beacon) {
  const navigationData = parseBeacon(beacon);

  if (navigationData) {
    if (options.trackTransition && !options.trackTransition(navigationData.url, switches)) {
      return;
    }

    const currentRoute = getHashPath(navigationData.url);

    const sendFn = beacon['http.initiator'] === 'spa' ? sendSoftNavigation : sendHardNavigation;

    console.debug(`Sending pageView metric: ${beacon['http.initiator']} of ${beacon.t_done}`);
    sendFn({
      currentRoute,
      ...navigationData,
    });
  }
}

function parseBeacon(beacon: Beacon) {
  if (beacon['http.initiator'] === 'spa' || beacon['http.initiator'] === 'spa_hard') {
    // Resources will be images or links
    const resources =
      beacon.resources &&
      beacon.resources.map(resource => ({
        href: resource.href || resource.src,
      }));

    const xhrResources =
      beacon.xhrResources &&
      beacon.xhrResources.map(xhrResource => ({
        timing: xhrResource.timing,
        duration: xhrResource.restiming && xhrResource.restiming.duration,
        startTime: xhrResource.restiming && xhrResource.restiming.startTime,
        url: xhrResource.url,
        existingResource: xhrResource.existingResource,
        requestFailed: xhrResource.requestFailed,
        endTime: xhrResource.endTime,
      }));

    const baseProps = {
      url: beacon.u,
      routeTransitionStart: beacon['rt.tstart'],
      firstPaint: beacon['pt.fp'],
      firstContentfulPaint: beacon['pt.fcp'],
      transitionId: beacon.transitionId,
      fromBoomerang: true,
      boomerangTiming: {
        resources,
        xhrResources,
      },
    };

    if (beacon['http.initiator'] === 'spa') {
      return { softNavigationTime: beacon.t_done, ...baseProps };
    }
    return {
      ...baseProps,
      hardNavigationTime: beacon.t_done,
      boomerangTiming: {
        ...baseProps.boomerangTiming,
        firstReactRenderTimestamp,
        minLoadTime: initializedMinPageLoad,
        aborted: beacon['rt.quit'],
        beaconAttempts: beacon['beacon_attempts'],
        beaconDefers: beacon['beacon_defer'],
        startTime: beacon['rt.tstart'],
      },
    };
  }
}
