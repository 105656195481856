import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import React, { ReactNode } from 'react';

import { sessionTimer, ApolloClientOptions } from 'z-frontend-app-bootstrap';
import renderApp from 'z-frontend-render-app';

import initialQueries from './initialQueries';
import localeData from './locales';
// TO-DO remove this after no users redirected to /app/boot/
const Shell = React.lazy(() => import(/* webpackPreload: true */ './Shell'));
const BootRouter = React.lazy(() => import('./BootRouter'));

addLocaleData([...en]); // date formats etc
declare const module: __WebpackModuleApi.Module;

const fragmentTypes = require('../schema/fragmentTypes.json');

const apolloClientOptions: ApolloClientOptions = {
  fragmentTypes,
  assumeImmutableResults: true,
};

function isLoadingReactDashboard() {
  const possibleHashValues = ['', '#', '#/'];
  return possibleHashValues.includes(window.location.hash);
}

const boomerangOptions = {
  ignoreImages: true,
  ignoreAddedStylesheets: true,
  enableBoomerangInEmber: true,
  // Ignore api calls made by ember
  xhrExcludeFilters: [
    (url: string) => {
      if (!url.includes('/graphql')) {
        return true;
      }
      return false;
    },
  ],
};

async function doRender() {
  let componentToRender: ReactNode;
  let acceptPath: string;

  if (window.location.href.includes('/boot')) {
    componentToRender = Shell;
    acceptPath = './Shell';
  } else {
    componentToRender = BootRouter;
    acceptPath = './BootRouter';
  }

  renderApp({
    localeData,
    boomerangOptions,
    initialQueries: isLoadingReactDashboard() ? initialQueries : null,
    App: componentToRender,
    apolloParams: apolloClientOptions,
    reduxParams: {
      reducers: {},
    },

    hotReloadCallback: (renderApp: Function) => {
      module.hot.accept(acceptPath, () => {
        renderApp(componentToRender);
      });
    },
    onBoot: () => {
      sessionTimer.start();
    },
    eventLoggerParams: {
      logPageViewsOnlyFromEmbeddedApps: true,
    },
  });
}

doRender();
