// @ts-ignore
import { getFirstConsistentlyInteractive } from 'tti-polyfill';

import { initialzePerformanceObserverForTTI } from './analyticsUtils';
import { disablePerformanceLogging } from './createApolloClient';
import { trackLongTasks } from './performanceMetrics';
import { bootBoomerang, setupFetchSubscription, BoomerangOptions } from './boomerang';
import { getEventLogger } from '..';

let performanceAnalyticsInitialized = false;

export function initializePerformanceAnalytics(boomerangOptions: BoomerangOptions) {
  if (!performanceAnalyticsInitialized) {
    try {
      // https://github.com/GoogleChromeLabs/tti-polyfill#usage
      initialzePerformanceObserverForTTI();
      // Start watching fetch calls
      setupFetchSubscription(boomerangOptions);
      trackLongTasks();
    } catch (e) {
      getEventLogger().logError(e);
    }
  }
  performanceAnalyticsInitialized = true;
}

export function applySwitchesToPerformanceAnalytics(
  switches: Record<string, string>,
  boomerangOptions: BoomerangOptions,
) {
  try {
    if (switches.react_gql_performance_kill_switch) {
      disablePerformanceLogging();
    }

    // TODO put behind switch
    bootBoomerang(boomerangOptions, switches);
  } catch (e) {
    getEventLogger().logError(e);
  }
}
