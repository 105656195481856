export function getHashPath(urlString: string) {
  const url = new URL(urlString);

  // Remove leading #
  return url.hash && url.hash.slice(1);
}

const PROD_HOSTS = ['secure.zenefits.com', 'console.zenefits.com'];

export const onProdHost = () => {
  return PROD_HOSTS.includes(window.location.hostname);
};
