// @ts-ignore
import { getFirstConsistentlyInteractive } from 'tti-polyfill';

import { getCommonTimingProps, getEventLogger } from './event-logger';

const HARD_NAVIGATION_LOG_ID = 'hardNavigation';

declare global {
  interface Window {
    PerformanceObserver?: any;
    PerformanceLongTaskTiming?: any;
  }
}

function getTTI() {
  return getFirstConsistentlyInteractive();
}

export function sendHardNavigation(data: Object) {
  getEventLogger().scheduleLog('pageview', { ...getCommonTimingProps(), ...data }, null, HARD_NAVIGATION_LOG_ID, 8000);
  getTTI().then((tti: number) => {
    getEventLogger().addEventProperties('hardNavigation', { timeToInteractive: tti });
  });
}

export function sendSoftNavigation(data: Object) {
  getEventLogger().log('pageview', { ...getCommonTimingProps(), ...data });
}

export function trackLongTasks() {
  if ('PerformanceLongTaskTiming' in window) {
    try {
      const observer = new window.PerformanceObserver((list: any) => {
        for (const entry of list.getEntries()) {
          // name is a reserved keyword for ui-logger
          const { name, attribution, ...rest } = entry.toJSON();
          const parsedAttribution = Array.isArray(attribution) ? attribution[0] : attribution;
          getEventLogger().batchLog('long-task', {
            transitionId: getEventLogger().transitionId,
            ...rest,
            longTaskName: name,
            attribution: parsedAttribution,
          });
        }
      });
      observer.observe({ entryTypes: ['longtask'] });
    } catch (err) {
      getEventLogger().logError(err);
    }
  }
}
