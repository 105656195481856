/**
 * Initial queries are defined in their own file to make the index bundle as small as possible. We can get a bundle ~10kb to fire initial queries with this approach
 * If we use queries defined in components, all of the component code + sub-dependencies get pulled into the index bundle, which really slows down the initial load
 */
import gql from 'graphql-tag';

import { topNavQuery } from 'z-frontend-layout';

export const employeeQuery = gql`
  query EmployeesQuery {
    dashboard {
      ...DashboardWorkers
    }
    readOnlyAccess {
      canEdit
    }
  }

  fragment DashboardWorkers on Dashboard {
    id
    permission
    dashboardTeammates(first: 16) {
      ...EmployeeFields
    }
    employees(status__in: [Act, Set, Req], type__in: [RE, TE, IN], first: 16, order_by: "-hireDate") {
      ...EmployeeFields
    }
    employee {
      ...EmployeeFields
    }
  }

  fragment EmployeeFields on AllEmployee {
    id
    last_name
    preferredOrFirstName
    hireDate
    photoUrl
    status
    allStatus
    employmentType
    isManager
    reportToEmployee {
      id
    }
  }
`;

export const companyContactsQuery = gql`
  query CompanyContactQuery {
    dashboard {
      id
      features
      employee {
        id
        employmentType
      }
      company {
        id
        hrProxy {
          id
          hrContactName
          hrContactEmail
        }
        benefitsContacts {
          firstName
          preferredFirstName
          lastName
          email
          phone
          phoneExtension
          contactTypes
        }
      }
    }
  }
`;

export const appsQuery = gql`
  query AppsQuery {
    dashboard {
      id
      isSpoofing
      isTrialCompany
      switches
      permission
      features
      company {
        id
        prospectAccount {
          type
        }
      }
      employee {
        id
        type
        employmentType
      }
      zAppInstallSubscriptions {
        id
        status
        inheritedStatus
        preferences
        appInstall {
          id
          status
          preferences
          company {
            id
          }
          app {
            id
            uniqueId
            appUrl
            role
            appIconSqUrl
            shortTitle
            status
            preferences
          }
        }
      }
    }
    readOnlyAccess {
      canEdit
    }
  }
`;

export const dashboardPageQuery = gql`
  query AppsAvailableQuery {
    dashboard {
      id
      isSpoofing
      permission
      switches
      features
      zAppInstallSubscriptions {
        id
        status
        inheritedStatus
        preferences
        appInstall {
          id
          status
          preferences
          company {
            id
          }
          app {
            id
            uniqueId
            appUrl
            role
            appIconSqUrl
            shortTitle
            status
            preferences
          }
        }
      }
    }
    prerequisiteRedirect
  }
`;

export const nameQuery = gql`
  query NameQuery {
    dashboard {
      id
      employee {
        id
        preferredOrFirstName
      }
    }
  }
`;

export const companyQuery = gql`
  query CompanyQuery {
    dashboard {
      id
      companyDisplayName
      company {
        id
        logoUrl
        name
      }
    }
  }
`;

const initialQueries = [
  { query: employeeQuery },
  { query: companyContactsQuery },
  { query: topNavQuery },
  { query: appsQuery },
  { query: dashboardPageQuery },
  { query: nameQuery },
  { query: companyQuery },
];

export default initialQueries;
