import { getEventLogger } from './event-logger'; //eslint-disable-line

// From https://github.com/GoogleChromeLabs/tti-polyfill#usage

export function initialzePerformanceObserverForTTI() {
  !(function() {
    if ('PerformanceLongTaskTiming' in window) {
      try {
        // eslint-disable-next-line no-multi-assign
        const g = (window.__tti = { e: [] });
        // eslint-disable-next-line compat/compat
        g.o = new PerformanceObserver(function(l) {
          g.e = g.e.concat(l.getEntries());
        });
        g.o.observe({ entryTypes: ['longtask'] });
      } catch (e) {
        getEventLogger().logError(e);
      }
    }
  })();
}
